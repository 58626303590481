import uniq from 'lodash/uniq'
import { buildServerSideContext, buildServerSideProps } from '@/utils/buildServerSide'
import baseRouter from '@/utils/baseRouter'

import { getLocations } from '@/services/yext'
import LocationsView from '@/views/Locations'

const REGIONS_ORDER = [
    'WEST',
    'SOUTHWEST',
    'MIDWEST',
    'SOUTHEAST',
    'NORTHEAST'
]

const router = baseRouter().get(async (_, res) => {
    const pageData = {
        title: 'Saatva Viewing Rooms',
        description: 'Visit our Saatva Viewing Rooms. Our luxurious and inviting space makes mattress shopping a relaxing, pressure-free experience.',
        pageType: 'info',
        breadcrumbs: [{
            label: 'Home',
            href: '/',
        },
        {
            label: 'Locations'
        }]
    }

    const locations = await getLocations()

    locations.sort((locationA, locationB) => {
        if (locationA.address.region > locationB.address.region) return 1
        if (locationA.address.region < locationB.address.region) return -1
        return 0
    })

    const regions = uniq(locations.map(location => location.c_region))
    regions.sort((regionA, regionB) => REGIONS_ORDER.indexOf(regionA) - REGIONS_ORDER.indexOf(regionB))

    pageData.locations = locations
    pageData.regions = regions

    return {
        props: buildServerSideProps(pageData, res.locals)
    }
})

export function getServerSideProps(context) {
    const { req, res } = buildServerSideContext(context)
    return router.run(req, res)
}

export default function LocationsPage(props) {
    return (
        <LocationsView {...props} />
    )
}
